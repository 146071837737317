<template>
  <div class="home">
    <div class="main">
      <div class="of" style="width: 1000px;margin: 0 auto;">
        <span class="fl" style="width:50px;display:block;height:40px;line-height:40px;">域名：</span>
        <input type="text" class="fl domain" placeholder="输入需要检测的域名(https/http必须输入)" v-model="domain">
        <button class="fl btn" @click="start" style="margin: 0px;">开始扫描</button>

      </div>
      <div class="of tag tc " style="width: 800px;margin: 10px auto;" v-text="msg">

      </div>
      <div class="tc">
        <button class="btn" @click="changeCheck">常规网站检测</button>
      </div>
      <div class="tc">
        <button  class="btn" @click="GetResult">点击查看结果</button>
      </div>
      <div v-if="showResult" style="width: 800px;margin: 0 auto;">
        <h3>内容安全检测结果</h3>
        <div>域名：{{domain}}</div>
        <h3>图片</h3>
        <div>检测通过数量：{{checkResult.pic.pass}}</div>
        <div>检测未通过数量：{{checkResult.pic.unpass.length}}</div>
        <table class="unpass" border="1" cellpadding="0">
          <tr>
            <th>域名</th>
            <th>图片</th>
            <th>label</th>
            <th>匹配度</th>
            <th>scene</th>
          </tr>
          <tr v-for="(item,index) in checkResult.pic.unpass">
            <td>{{item.domain}}</td>
            <td><img :src="`${domain}${item.content}`" alt="" width="100"></td>
            <td>{{item.label}}</td>
            <td>{{item.rate}}</td>
            <td>{{item.scene}}</td>
          </tr>
        </table>
        <h3>文本</h3>
        <div>检测通过数量：{{checkResult.text.pass}}</div>
        <div>检测未通过数量：{{checkResult.text.unpass.length}}</div>
        <table class="unpass" border="1" cellpadding="0">
          <tr>
            <th>域名</th>
            <th>链接</th>
            <th>违规内容</th>
            <th>label</th>
            <th>匹配度</th>
            <th>scene</th>
          </tr>
          <tr v-for="(item,index) in checkResult.text.unpass">
            <td>{{item.domain}}</td>
            <td><a :href="`${domain}${item.content}`" target="_blank">{{item.content}}</a></td>
            <td>{{item.context}}</td>
            <td>{{item.label}}</td>
            <td>{{item.rate}}</td>
            <td>{{item.scene}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {getImgList,getHtmlList,GetResult,Checking} from "@/api";

export default {
  name: 'About',
  data(){
    return{
      domain:'http://m.weipujishu.com',
      dir:'',
      msg:"",
      imgList:[],
      imgTotal:0,
      type:1,
      i:0,
      j:0,
      showResult:false,
      checkResult:[],
      htmlList:[],
      htmlTotal:0,
      z:0
    }
  },
  methods:{
    async start(){
      this.htmlTotal = 0;
      this.htmlList = [];
      this.imgTotal = 0;
      this.imgList = [];
      this.i = 0;
      this.j = 0;
      if(this.domain){
        const result = await getImgList(this.domain);
        if(result.code==1){
          this.imgList = result.data.lists;
          this.imgTotal = result.data.total;
          this.type = 1;
          this.getHtmlList();
          this.ckeching();
        }else{
          this.msg = result.msg
        }
      }else{
        this.msg = "请填写域名"
      }
    },
    async getHtmlList(){
      if(this.domain){
        const result = await getHtmlList(this.domain);
        if(result.code==1){
          this.htmlList = result.data.lists;
          this.htmlTotal = result.data.total;
          // this.type = 1;
          // this.ckeching();
        }
      }else{
        // alert();
        this.msg = "请填写域名"
      }
    },
    changeCheck(){
      this.$router.push({ path:'/'  })
    },
    ckeching(){
      (this.type==1) ? this.msg = "开始扫描图片" : this.msg = "开始扫描文本";
      (this.type==1) ? this.checkImg() : this.checkText()
    },
    async checkImg(){
      if(this.i<this.imgTotal){
        this.msg = `正在检测图片：${this.imgList[this.i]}`;
        const result = await Checking(this.domain,this.imgList[this.i],1);
        this.i++;
        this.checkImg();
        // if(result.code==1){
        //
        // }
      }else{
        this.type=2;
        this.ckeching();
      }
    },
    async checkText(){
      if(this.j<this.htmlTotal){
        this.msg = `正在检测图片：${this.htmlList[this.j]}`;
        const result = await Checking(this.domain,this.htmlList[this.j],2);
        this.j++;
        this.checkText();
      }else{
        this.msg = "扫描完成";
      }
    },
    async GetResult(){
      const result = await GetResult(this.domain);
      this.checkResult = result.data;
      this.showResult = true
    }
  }
}
</script>
<style>
*{
  padding: 0px;
  margin: 0px;
  font-size: 14px;
}
.main{
  width: 800px;
  margin: 20px auto;
}
.tc{
  text-align: center;
}
.of{
  overflow: hidden;
}
.fl{
  float: left;
}
.btn{
  background: #4e6ef2;
  color: #fff;
  border: none;
  cursor: pointer;
  padding:0px 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  margin: 10px 0px;
}
.domain{
  width: 590px;
  height: 38px;
  line-height: 38px;
  border-right: none;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  outline: none;
  padding-left: 10px;
}
.error{
  color: red;
}
.unpass{
  width: 800px;
  margin: 0 auto;
  border-collapse: collapse;
}
.unpass th,td{
  text-align: center;
}
</style>
